<template>
  <article @click="goToArticle(article)" v-if="article" class="media">
    <figure class="media-left">
      <p class="image is-64x64">
        <img
          key="image"
          v-if="article.LocationId > 0 && article.ImageUrl"
          :src="article.ImageUrl | getLocationImage(article.LocationId, '320')"
          class="image"
        />

        <img
          key="imageExternal"
          v-if="article.LocationId === 0 && article.ImageUrl"
          :src="article.ImageUrl"
          class="image"
        />
      </p>
    </figure>
    <div class="media-content">
      <div class="content">
        <p>
          <strong>{{ article.Title }}</strong>
          <small>{{ article.Author }}</small>
          <small>{{ article.Date }} </small>
          <br />
          {{ article.Short }}
        </p>
      </div>
      <!-- <nav class="level is-mobile">
        <div class="level-left">
          <a class="level-item">
            <span class="icon is-small"><i class="fas fa-reply"></i></span>
          </a>
          <a class="level-item">
            <span class="icon is-small"><i class="fas fa-retweet"></i></span>
          </a>
          <a class="level-item">
            <span class="icon is-small"><i class="fas fa-heart"></i></span>
          </a>
        </div>
      </nav> -->
    </div>
    <!-- <div class="media-right">
      <button class="delete"></button>
    </div> -->
  </article>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    article: {
      type: Object,
      default: null
    }
  },

  data() {
    return {}
  },

  computed: {},

  created() {},

  methods: {
    ...mapMutations('articleStore', ['setArticle']),

    goToArticle(article) {
      this.setArticle(article)

      this.$router.push({
        name: `article-detail`,
        params: { articleId: article.Id }
      })
    }
  }
}
</script>

<style></style>
